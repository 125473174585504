import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import type { MetaFunction } from '@remix-run/node'
import { Link } from '@remix-run/react'

export const meta: MetaFunction = () => [{ title: 'FAQ | fuse.space' }]

export const faq = [
  {
    title: 'What is a fuse page?',
    content: (
      <>
        <p>
          When you send work, you want control over how something looks, how it
          sounds, and how it’s secured. All of that is contained in a page. Set
          up how you want your work presented visually, choose how you want
          material used (including limiting music to streaming only and
          restricting downloads), and set up security features. By working with
          pages, your work will always stand out, and you can protect your
          intellectual property and authorship.
        </p>

        <p className='pt-5'>
          Other file sharing services often fail on these same points. They
          might present an ugly, distracting file folder interface, add lots of
          distractions and confusing options, or make recipients download files
          to hear music. We believe in making something that’s fast and easy to
          make sure your work gets heard. They may also provide limited security
          (or none), so your work could be ripped off even without your
          knowledge.
        </p>
      </>
    ),
  },
  {
    title:
      'Why is sharing with fuse.space  more secure than sharing with other services?',
    content: (
      <>
        <p>
          fuse.space provides greater security and trust on multiple levels:
        </p>

        <ul className='list-disc pl-8 pt-5'>
          <li>
            Every time you upload a file to fuse.space, it’s{' '}
            <span className='font-bold'>
              recorded and cryptographically secured{' '}
            </span>
            using blockchain technology. This provides immutable proof of
            ownership, because records on the blockchain can’t be altered or
            deleted. That provides verifiable evidence of your ownership at a
            specific point in time, which helps to fight and win disputes around
            your intellectual property.
          </li>
          <li className='py-2'>
            You can <span className='font-bold'>customize access settings</span>
            , including setting file options to downloadable or streaming only,
            password-protected pages, watermarking files, and setting page
            expiry. (Password, watermark, and custom expiry settings are coming
            soon and will require a Pro plan; all other features are available
            across all plans.)
          </li>
          <li>
            We’re working to build{' '}
            <span className='font-bold'>
              legally validated and audited solutions
            </span>{' '}
            by partnering with world-renowned experts including the Fraunhofer
            SIT and CISPA / Helmholtz Institute. (We’ll share those results as
            they become available).
          </li>
        </ul>
        <p className='pt-5'>
          Taken together, that gives fuse.space an unparalleled advantage in
          protecting your work and providing records when you need them.
        </p>
      </>
    ),
  },
  {
    title: 'Is fuse.space free?',
    content: (
      <p>
        Our basic plan, available now, is totally free - and will stay that way.
        Coming soon, we’ll offer additional advanced features and more storage
        on the Creator and Pro tiers, plus solutions tailored for teams and
        enterprises. See{' '}
        <Link to={{ pathname: '/', hash: '#pricing' }} className='underline'>
          pricing
        </Link>{' '}
        for more details.
      </p>
    ),
  },
  {
    title: 'How do I log into my old account?',
    content: (
      <p>
        You can access the old service at its previous domain:{' '}
        <a href='https://app.fuse.space/login' className='underline'>
          https://app.fuse.space/login
        </a>
        . But great news - our new service is now live! Register today and
        experience sharing with fuse.pages. Thank you for being with us!
      </p>
    ),
  },
  {
    title:
      'Wait, blockchain? What? Is this going to mean I need some crypto stuff just to use this? ',
    content: (
      <p>
        No, you can create a normal login and use fuse.space just like any other
        service - the proof of ownership and proof of authentication all happens
        behind the scenes, without your intervention. Files themselves aren’t
        stored on the blockchain; only the upload records are. Your content is
        covered by strict privacy laws and our strong encryption and security.
        For all the complex solutions built on the blockchain and overhyped
        crypto tech, storing these sorts of immutable records was what the
        blockchain was originally engineered to do.
      </p>
    ),
  },

  {
    title: 'How can I create a fuse page?',
    content: (
      <>
        <p>It's easy:</p>
        <ol className='pl-8 list-decimal pt-5'>
          <li>
            <span className='font-bold'>CREATE:</span> Start your page by
            uploading your file(s). You can also name your page and add a
            description.
          </li>
          <li className='py-2'>
            <span className='font-bold'>OWN:</span> Set any custom
            security/access settings you want.
          </li>
          <li>
            <span className='font-bold'>SHARE:</span> Click “share” to make your
            page go live.
          </li>
        </ol>
      </>
    ),
  },

  {
    title: 'What kind of files can I share?',
    content: (
      <p>
        Audio files can be previewed and streamed directly on the page, but you
        can upload any type of file you want. Need to share assets for an album
        cover, or stems for a remix, or 3D files for your indie game? No problem
        - everything is available, neatly organized on your page, with all our
        security features. You’re limited only by available storage and maximum
        file size on your plan. (3GB is the maximum file and page size for an
        individual file on the free plan.)
      </p>
    ),
  },
  {
    title: 'What format should I upload audio for streaming and downloading?',
    content: (
      <p>
        Any media type can be used for downloads up to the maximum file size of
        3 GB. For streaming audio, you’ll need to use WAV, MP3, AIFF, FLAC, OGG,
        and AAC (and most variations).
      </p>
    ),
  },
  {
    title: 'Can I update my page after sharing it?',
    content: (
      <p>
        Yes, you can modify the page, its settings, and any files at any time;
        anyone with the link will see updates instantly.{' '}
      </p>
    ),
  },
  {
    title:
      'Is there a file size limit for files and pages? What if I need more space?    ',
    content: (
      <>
        <p>
          In the current version, you can upload an unlimited number of spaces
          and files. You’re limited only by maximum file size, page size, and
          total storage. In the current free plan, you have the following
          maximums:
        </p>
        <ul className='list-disc py-5'>
          <li>
            <span className='font-bold'>Individual files:</span> 3GB
          </li>
          <li className='py-2'>
            <span className='font-bold'>Individual pages:</span> 3GB
          </li>
          <li>
            <span className='font-bold'>Total combined space:</span> 3GB
          </li>
        </ul>
        <p>
          For those needing bigger files or more flexibility, additional paid
          options are coming soon. For details, see{' '}
          <Link to={{ pathname: '/', hash: '#pricing' }} className='underline'>
            pricing
          </Link>
          .
        </p>
      </>
    ),
  },
  {
    title: 'What is a fuse.space certificate?',
    content: (
      <p>
        Every time you upload a file to fuse.space, you get a unique certificate
        of ownership - one for each individual file. This gives you
        cryptographic proof of possession and the authenticity of the work
        uploaded at a specific point in time. This certificate alone doesn’t
        establish copyright, but it proves that you had the file in your
        possession, which is essential evidence in legal disputes. This is
        legally recognized documentation that isn’t provided by conventional
        storage or cloud services.
      </p>
    ),
  },
  {
    title: 'How do I verify my intellectual property?',
    content: (
      <p>
        Detailed instructions on verifying your intellectual property will be
        provided soon. (Upload records are already in place each time you
        upload.)
      </p>
    ),
  },
  {
    title: 'What form of audio watermarking do you offer?',
    content: (
      <p>
        In collaboration with Fraunhofer SIT, we’ve developed an advanced,
        extremely robust audio watermark. It’s nearly inaudible to your ear, but
        it can be detected digitally even when the audio is mixed or processed -
        like if you’re using it in a DJ mix, remix, or sample. Fully exploiting
        fuse.space’s page paradigm, each watermark is unique and traceable back
        to the specific page from which it was shared. We’ll provide
        watermarking soon on our Pro plan as an additional layer of security for
        those with more advanced needs.
      </p>
    ),
  },
  {
    title: 'Is fuse.space GDPR compliant? Are my files secure?',
    content: (
      <>
        <p>
          Yes, our servers are located in Europe, and all data handling and
          storage meet stringent GDPR standards.
        </p>
        <p className='pt-5'>
          All files are encrypted when stored, making them unreadable to anyone
          else. This protects sensitive information from unauthorized access and
          data breaches, helping to comply with data protection regulations.
        </p>
        <p className='pt-5'>
          Additionally, we store a unique fingerprint (cryptographic hash) each
          time you upload files on the Polygon Blockchain. It’s not the file
          itself - it’s the record of your upload and user ID, stored securely.
          With that fingerprint, your ownership and intellectual property are
          immutably recorded on the blockchain, making the originality of your
          work verifiable.
        </p>
      </>
    ),
  },
  {
    title: 'Why did you build fuse.space?',
    content: (
      <>
        <p>
          We’re artists, too, and we wanted more than what current tools were
          giving us.
        </p>
        <p className='pt-5'>
          Most tools are drab, ugly, and clunky to use. There’s no reason to
          take work you’re passionate about and share it through a page that
          looks like a 90s computer file system. So we made one we’d actually
          want to use - one that works easily and elegantly and allows your work
          to look and sound good.
        </p>
        <p className='pt-5'>
          We also believe that you can’t be free to collaborate and share
          creatively if you have to worry about your work being ripped off. More
          secure tools are often available to big artists, but everyone’s work
          has potential value - and it’s often newcomers who are most vulnerable
          to exploitation. So we built a tool to make sharing secure. That
          includes the free tier, so it’s available to everyone. We hope that’ll
          encourage you to work more with others, not just alone.
        </p>
      </>
    ),
  },
  {
    title:
      'How do I delete files, pages or my account? Are those files then deleted forever?',
    content: (
      <p>
        To delete your account, go to User Settings, click your profile icon,,
        and choose "Delete Account " at the bottom of the page. You will be
        prompted to download your created certificates and related files, which
        we recommend to ensure you retain proof of ownership. Records on the
        blockchain remain, but all your files are permanently deleted and
        inaccessible.
      </p>
    ),
  },
  {
    title: 'Can I restore files after they expire?',
    content: (
      <p>
        For free users, files and fuse pages cannot be restored once they
        expire. With our Creator and Pro tiers, you can set custom expiry dates
        and seamlessly restore expired pages and content. See{' '}
        <Link to={{ pathname: '/', hash: '#pricing' }} className='underline'>
          pricing
        </Link>{' '}
        for more details. And across all plans, you can always keep making
        unlimited pages until you hit the maximum available storage limit for
        the account.
      </p>
    ),
  },
]

export default function MarketingFaq() {
  return (
    <div className='container max-w-3xl py-6 sm:py-16'>
      <h1 className='text-5xl sm:text-6xl font-bold lowercase text-center'>
        FAQ
      </h1>

      <Accordion type='single' collapsible className='w-full mt-12'>
        {faq.map((faq, index) => (
          <AccordionItem key={faq.title} value={`item-${index + 1}`}>
            <AccordionTrigger className='text-left items-start lg:items-center lg:text-center'>
              {faq.title}
            </AccordionTrigger>
            <AccordionContent>{faq.content}</AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}
